import { Injectable } from '@angular/core';


export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permisos?:{};
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const menu2 = {
  rutas:0,
  eventos:0,
  clientes:0,
  personal:0,
  secciones:0,
  restaurantes:0,
  notifiaciones:0,
  categorias:0,
  imagenesportadas:0,
  calendario:0
};

console.log(menu2);


            const MENUITEMS = [
              {
                state: 'rutas',
                name: 'Rutas',
                type: 'link',
                icon: 'accessibility',
                permisos: menu2.rutas
              },
              {
                state: 'eventos',
                name: 'Eventos',
                type: 'link',
                icon: 'comment',
                permisos: menu2.eventos
              },
              {
                state: 'calendario',
                name: 'Calendario',
                type: 'link',
                icon: 'calendar_today',
                permisos: menu2.calendario
              },
              {
                state: 'restaurantes',
                name: 'Restaurantes',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.restaurantes
              },
              {
                state: 'categorias',
                name: 'Categorias',
                type: 'link',
                icon: 'book',
                permisos: menu2.categorias
              },
              {
                state: 'personal',
                name: 'Personal',
                type: 'link',
                icon: 'calendar_view_day',
                permisos: menu2.personal
              },
               {
                state: 'imagenesportadas',
                name: 'Portada',
                type: 'link',
                icon: 'image',
                permisos: menu2.imagenesportadas
              },
              {
                state: 'secciones',
                name: 'Secciones',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.secciones
              }
            ];

// content_copy



@Injectable()
export class MenuItems {
  menu2 : {};
  getMenuitem(): Menu[] {
    
   

    return MENUITEMS;
  }
}
