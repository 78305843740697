import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService,private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var valor = this.auth.getToken();
    //alert(valor);


    if(valor != null){
   
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${valor}`
        }
      });
    
    } else {
      this.router.navigate(['./authentication/login']);
    }
    return next.handle(request);
  }
}